<template>
  <div class="test">
    <div class="t">
      <button @click="changePdfPage(0)">上一页</button>
      <pdf
        :src="src"
        :page="currentPage"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
        @loaded="loadPdfHandler"
      >
      </pdf>
      <button @click="changePdfPage(1)">下一页</button>
    </div>
    <div>
      <input type="text" v-model="val" /><button @click="test">添加</button>
      <div>
        {{ inp }}
      </div>
      <div>
        {{ val }}
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { getId } from "@/utils/validate";

export default {
  name: "test",
  components: {
    pdf,
  },
  data() {
    return {
      currentPage: 1, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      src: "", // pdf文件地址
      t: [
        {
          id: "193AD0AE78CB4433A4239570D3782124",
          code: "promotion",
          name: "企业侧管理平台-促销管理",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "33552C31C8714375A647B7579DAEB572",
              code: "promotion",
              name: "已发布广告",
              urlPath: "/business-server/AdvertisementMgt/advertisementList",
              parentId: "193AD0AE78CB4433A4239570D3782124",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "3DEC53358EFB43848D714DC6D7D44CCB",
              code: "promotion",
              name: "已发布活动",
              urlPath: "/business-server/Activity/queryActivityList",
              parentId: "193AD0AE78CB4433A4239570D3782124",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "6A2C83F49CC8473E871600F794BFAC48",
              code: "promotion",
              name: "判断广告位置是否被占用",
              urlPath: "/business-server/AdvertisementMgt/boolAdvertisement",
              parentId: "193AD0AE78CB4433A4239570D3782124",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: [
                {
                  id: "33552C31C8714375A647B7579DAEB3-1",
                  code: "promotion",
                  name: "已发布广告3-1",
                  urlPath:
                    "/business-server/AdvertisementMgt/advertisementList",
                  parentId: "193AD0AE78CB4433A4239570D3782124",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "3DEC53358EFB43848D714DC6D7D443-1",
                  code: "promotion",
                  name: "已发布活动3-1",
                  urlPath: "/business-server/Activity/queryActivityList",
                  parentId: "193AD0AE78CB4433A4239570D3782124",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
              ],
            },
            {
              id: "6E7EC06BECD1420EA7ACB3087A03A729",
              code: "promotion",
              name: "特惠商品列表",
              urlPath: "/business-server/Activity/preferentialList",
              parentId: "193AD0AE78CB4433A4239570D3782124",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: [
                {
                  id: "33552C31C8714375A647B7579DAEB3-2",
                  code: "promotion",
                  name: "已发布广告3-2",
                  urlPath:
                    "/business-server/AdvertisementMgt/advertisementList",
                  parentId: "193AD0AE78CB4433A4239570D3782124",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "3DEC53358EFB43848D714DC6D7D443-2",
                  code: "promotion",
                  name: "已发布活动3-2",
                  urlPath: "/business-server/Activity/queryActivityList",
                  parentId: "193AD0AE78CB4433A4239570D3782124",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "3DEC53358EFB43848D714DC6D7D3-3sg",
                  code: "sgmall",
                  name: "已发布活动3-3",
                  urlPath: "/business-server/Activity/queryActivityList",
                  parentId: "193AD0AE78CB4433A4239570D3782124",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
              ],
            },
            {
              id: "E716C79B298547BEA9D744DFB8F16B31",
              code: "promotion",
              name: "活动详情",
              urlPath: "/business-server/Activity/queryActivity",
              parentId: "193AD0AE78CB4433A4239570D3782124",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "22897FBBC23744DF8A5E911F3643FABF",
          code: "logistics",
          name: "企业侧管理平台-物流配送",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "487E901134F9434BAE63CEEAA144C2BC",
              code: "logistics",
              name: "物流轨迹",
              urlPath: "/business-server/Waybill/queryRoute",
              parentId: "22897FBBC23744DF8A5E911F3643FABF",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "763D9F409DFB4078ACC3F7D57307ACDF",
              code: "logistics",
              name: "运单详情",
              urlPath: "/business-server/Waybill/WaybillDetails",
              parentId: "22897FBBC23744DF8A5E911F3643FABF",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "ECEC79E4200E4E0EA1E3A4AAC0F36BE5",
              code: "logistics",
              name: "物流订单列表",
              urlPath: "/business-server/Waybill/WaybillList",
              parentId: "22897FBBC23744DF8A5E911F3643FABF",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "48E287EB295244328AF79A897ADA4EF4",
          code: "sgmall",
          name: "韶关交易平台",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "2D262D023F4A4C669672EEAA2EDB7D60",
              code: "sgmall",
              name: "店铺列表",
              urlPath: "/business-server/HomePage/StoreList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "395B0AB2BCB144658D6011C62882A79C",
              code: "sgmall",
              name: "活动",
              urlPath: "/business-server/Activity/homeActivityList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "397AA50426804DA9AD9E9AE5999323C7",
              code: "sgmall",
              name: "全部品牌",
              urlPath: "/business-server/HomePage/brandInitialsList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "46A8D14E731E49A7AB48836A4CBF5772",
              code: "sgmall",
              name: "模糊查询",
              urlPath: "/business-server/HomePage/queryCommList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "573E1BA623B94633BECFBB400DDFD4CD",
              code: "sgmall",
              name: "品牌详情",
              urlPath: "/business-server/HomePage/brandInfo",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "6488B0E5A79F4DD3ADA5FE2D4F5D3DB3",
              code: "sgmall",
              name: "品牌列表",
              urlPath: "/business-server/HomePage/queryBrandList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "681004306F2C48778C06B4AB7D95B2A9",
              code: "sgmall",
              name: "商品详情",
              urlPath: "/business-server/CommodityMgt/CommodityDetails",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "745A8332154E413F948329753F2B90F3",
              code: "sgmall",
              name: "首页轮播图",
              urlPath: "/business-server/HomePage/slideshow",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "7B38501706DB42079429C8E32400D87B",
              code: "sgmall",
              name: "全部分类",
              urlPath: "/business-server/Classify/queryCategoriesCatalogApi",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "A2754AD930414733BB36B85ABE215D16",
              code: "sgmall",
              name: "商品列表",
              urlPath: "/business-server/HomePage/CommList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "BE3CF8D17AEC4E09B03FE03D030E0A08",
              code: "sgmall",
              name: "品牌查询",
              urlPath: "/business-server/HomePage/findBrandInfoList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "D17AB6819F0F4683A4C3C7511DB7396E",
              code: "sgmall",
              name: "活动商品",
              urlPath: "/business-server/Activity/homeActivityComm",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "DA2253F42D4941BF9908855D39837CB3",
              code: "sgmall",
              name: "店铺活动",
              urlPath: "/business-server/Activity/homeStoreActivityList",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "DAA6E7504F724A4197833E480C2B734A",
              code: "sgmall",
              name: "全部店铺",
              urlPath: "/business-server/HomePage/EnterpriseHomePage",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "DBFF28CA99474A3FBCD91E132B2393CE",
              code: "sgmall",
              name: "店铺轮播图",
              urlPath: "/business-server/HomePage/storeSlideshow",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "DC6A31AE92074B63A4C6D507CAE1F996",
              code: "sgmall",
              name: "店铺类目",
              urlPath:
                "/business-server/Classify/queryStoreIdCategoriesCatalog",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "F629AE0150B444B78D1EF634BFCDB288",
              code: "sgmall",
              name: "店铺首页",
              urlPath: "/business-server/HomePage/StoreHomePage",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "F744EBA71F6548A48E9824DC5B98C400",
              code: "sgmall",
              name: "商品价格详情",
              urlPath: "/business-server/CommodityMgt/CommodityPriceDetails",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "FF190D5F8AB54903BD6DE1C6E1AE6F91",
              code: "sgmall",
              name: "活动列表",
              urlPath: "/business-server/Activity/homeActivity",
              parentId: "48E287EB295244328AF79A897ADA4EF4",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
          ],
        },
        {
          id: "4ECA71CF89D74EA9B24C153B921E54D9",
          code: "oa",
          name: "OA",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "0B361FAAE36742BE82846B05F66691AD",
              code: "oa",
              name: "删除附件",
              urlPath: "/oa-server/Document/deleteAttachment",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "0FC606A28A68442A8BBA4C8114E9BD12",
              code: "oa",
              name: "上传附件列表",
              urlPath: "/oa-server/Document/attachmentsList",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "17DA3E63FC1D426B867BE72A87B2D23B",
              code: "AUTHORIZATION_REQUIRED",
              name: "处理意见",
              urlPath: "/oa-server/Document/receiverTS",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "1AA15A133D0E44A2938377C2B8C41C71",
              code: "oa",
              name: "新增公文",
              urlPath: "/oa-server/Document/createNewDocument",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "5A29381E2D824FB39008B7C463F4DBA8",
              code: "oa",
              name: "模板列表",
              urlPath: "/oa-server/Template/selectListTemplate",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "62F91046504E4F8C87C809EED7CF965B",
              code: "oa",
              name: "预览公文",
              urlPath: "/oa-server/Document/preNewDocument",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "6FC326F0AB604AAA8B3383F17B7AA9C7",
              code: "oa",
              name: "转已办件",
              urlPath: "/oa-server/Document/handleDocument",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "7BD46AB894FD4E088C9543E18872D501",
              code: "oa",
              name: "撤回审批件",
              urlPath: "/oa-server/Document/recallDocument",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "9CB4B4A374B247519E02136838795E7E",
              code: "oa",
              name: "新建模板",
              urlPath: "/oa-server/Template/createTemplate",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "A1AB8DBD7C7C408A8233C231873E03E7",
              code: "oa",
              name: "转发公文",
              urlPath: "/oa-server/Document/forwardDocument",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "A49370726B744179AD4C807A4C29DBC2",
              code: "oa",
              name: "公文流水状态",
              urlPath: "/oa-server/Document/runningTabList",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "A910FA3BE8F744959CBDD600EEE20A3F",
              code: "oa",
              name: "删除模板",
              urlPath: "/oa-server/Template/deleteTemplate",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "BB4EC4124595488DA472F7C7979AD963",
              code: "oa",
              name: "转平台审核",
              urlPath: "/oa-server/Audit/submitForAudit",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "BE900FDCAC824C0FA6E75CE454E2B485",
              code: "oa",
              name: "上传附件",
              urlPath: "/oa-server/Document/uploadAttachments",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "E3AB30232C8E446B9EE4A697248126DF",
              code: "oa",
              name: "公文详情",
              urlPath: "/oa-server/Document/documentDetail",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "F99EC61710AB4E11A744BD7DC0823161",
              code: "oa",
              name: "公文列表",
              urlPath: "/oa-server/Document/documentList",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "F9FF2C28BF8040DAA4179DFD0E2EA575",
              code: "oa",
              name: "删除公文",
              urlPath: "/oa-server/Document/deleteDocument",
              parentId: "4ECA71CF89D74EA9B24C153B921E54D9",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "51308F0446624A43A3818BDD66677289",
          code: "publishInfo",
          name: "企业侧管理平台-信息发布",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "8FC7F94498F845208D01006CA7BBFD70",
              code: "publishInfo",
              name: "商品信息",
              urlPath: "",
              parentId: "51308F0446624A43A3818BDD66677289",
              resourceAuthLevel: "PUBLIC",
              subResourceList: [
                {
                  id: "0AF7AAB6D6214C45AAA38B0723303C12",
                  code: "publishInfo",
                  name: "商品详情",
                  urlPath: "/business-server/CommodityMgt/CommodityDetails",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "15ED8F4472734247A6D54B67349E9B9F",
                  code: "publishInfo",
                  name: "未发布商品价格列表",
                  urlPath:
                    "/business-server/CommodityMgt/UndeterminedPriceCommodityList",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "1993EB0708FB45C6B1C8BBC36AC2AD84",
                  code: "publishInfo",
                  name: "未定店铺商品列表",
                  urlPath:
                    "/business-server/CommodityMgt/UndeterminedCommodityList",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "1DC266924316441E98DC17B6D875CE11",
                  code: "publishInfo",
                  name: "单品价格详情",
                  urlPath:
                    "/business-server/CommodityMgt/CommodityCodePriceDetails",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "449B258D13C440EC8B2BD9F884918A1A",
                  code: "publishInfo",
                  name: "已发布商品列表",
                  urlPath:
                    "/business-server/CommodityMgt/PublishedCommodityList",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "62B9651D7B3B443E83214D9D1C98C7FB",
                  code: "publishInfo",
                  name: "已发布单品列表",
                  urlPath:
                    "/business-server/CommodityMgt/PublishedCommodityCodeList",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "70ABEB716B8840C6AB3781377623273C",
                  code: "publishInfo",
                  name: "非单品价格详情",
                  urlPath:
                    "/business-server/CommodityMgt/CommodityPriceDetails",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "886693CD4F954456993421B00EDC21EA",
                  code: "publishInfo",
                  name: "商品分类",
                  urlPath: "/business-server/Classify/queryCategoriesCatalog",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "8987B30AE9B7490191DC8CC0BA280DBF",
                  code: "publishInfo",
                  name: "商品类目列表",
                  urlPath: "/business-server/Classify/Catalog",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "8AE8693B2CF84596B4FA08D87E0435CA",
                  code: "publishInfo",
                  name: "三级联动目录列表",
                  urlPath: "/business-server/Classify/queryCategoriesCatalog",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "9A8CD0B564BF4FCFB5B85AB3F9384687",
                  code: "publishInfo",
                  name: "交易中心分类",
                  urlPath:
                    "/business-server/Classify/queryCategoriesCatalogApi",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "C01604ADE2634E68B844537549E2625C",
                  code: "publishInfo",
                  name: "已发布品牌列表",
                  urlPath:
                    "/business-server/Enterprise/findCurrEnterpriseSellBrandInfoList",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "C286948BA6F949D899C2EA3E64F29B8A",
                  code: "publishInfo",
                  name: "新建商品类目",
                  urlPath: "/business-server/Classify/UpdateClassify",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "E62227A44E824F708FC8FA8C28521919",
                  code: "publishInfo",
                  name: "删除商品类目",
                  urlPath: "/business-server/Classify/DeleteClassify",
                  parentId: "8FC7F94498F845208D01006CA7BBFD70",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
              ],
            },
            {
              id: "B8C3387E68A94CADA32E7B441EE72F33",
              code: "publishInfo",
              name: "企业信息",
              urlPath: "",
              parentId: "51308F0446624A43A3818BDD66677289",
              resourceAuthLevel: "PUBLIC",
              subResourceList: [
                {
                  id: "17F6F1B92B754523BFA381AAFDA761A7",
                  code: "publishInfo",
                  name: "注册买家信息",
                  urlPath: "/business-server/Enterprise/registerBuyerInfo",
                  parentId: "B8C3387E68A94CADA32E7B441EE72F33",
                  resourceAuthLevel: "IDENTITY_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "3A12E9E422BD4A71911BCD7516E1098A",
                  code: "publishInfo",
                  name: "注册卖家信息",
                  urlPath: "/business-server/Enterprise/registerSellerInfo",
                  parentId: "B8C3387E68A94CADA32E7B441EE72F33",
                  resourceAuthLevel: "IDENTITY_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "60A8766265D848DE9F95E7E9BC0889C9",
                  code: "publishInfo",
                  name: "注册企业",
                  urlPath: "/business-server/Enterprise/registerEnterprise",
                  parentId: "B8C3387E68A94CADA32E7B441EE72F33",
                  resourceAuthLevel: "IDENTITY_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "D3278310D2C0496AB6ABD69FA33DE0E5",
                  code: "publishInfo",
                  name: "根据ID查询企业信息",
                  urlPath: "/business-server/Enterprise/findEnterpriseInfoById",
                  parentId: "B8C3387E68A94CADA32E7B441EE72F33",
                  resourceAuthLevel: "LOGIN_REQUIRED",
                  subResourceList: null,
                },
              ],
            },
            {
              id: "F521F8B0804949A6A76C293A7F95FD57",
              code: "publishInfo",
              name: "店铺信息",
              urlPath: "",
              parentId: "51308F0446624A43A3818BDD66677289",
              resourceAuthLevel: "PUBLIC",
              subResourceList: [
                {
                  id: "1505559456924E38A7BF828CAD5494F3",
                  code: "publishInfo",
                  name: "删除店铺匹配商品",
                  urlPath: "/business-server//StoreMgt/DeleteMatchCommodity",
                  parentId: "F521F8B0804949A6A76C293A7F95FD57",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "89A3009805DE4EA1A4C5176EA7C3EABA",
                  code: "publishInfo",
                  name: "店铺匹配商品",
                  urlPath: "/business-server/StoreMgt/MatchCommodity",
                  parentId: "F521F8B0804949A6A76C293A7F95FD57",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "A3A883A57C944892A7FCC3C66F8DE9D1",
                  code: "publishInfo",
                  name: "已发布店铺列表",
                  urlPath: "/business-server/StoreMgt/PublishedStoreList",
                  parentId: "F521F8B0804949A6A76C293A7F95FD57",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "DD4949E9B4C14AB59120432AF0BF1AAF",
                  code: "publishInfo",
                  name: "店铺匹配商品列表",
                  urlPath: "/business-server/StoreMgt/MatchCommodityList",
                  parentId: "F521F8B0804949A6A76C293A7F95FD57",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "DF6886709D3D4ECFACB3D6E2F244FB4B",
                  code: "publishInfo",
                  name: "店铺详情",
                  urlPath: "/business-server/StoreMgt/StoreDetails",
                  parentId: "F521F8B0804949A6A76C293A7F95FD57",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "E10F1EEBCE374D9A837A4A45EAF3E787",
                  code: "publishInfo",
                  name: "修改店铺匹配商品",
                  urlPath: "/business-server/StoreMgt/UpdateMatchCommodity",
                  parentId: "F521F8B0804949A6A76C293A7F95FD57",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
              ],
            },
          ],
        },
        {
          id: "6E6CCAB337A34747A9AE02411DF39034",
          code: "enterprise",
          name: "企业",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "8D225DBA06694774A456B6291032F3D8",
              code: "findCurrentIdentityEnterpriseId",
              name: "获取当前用户企业id",
              urlPath:
                "/business-server/BusinessUser/findCurrentIdentityEnterpriseId",
              parentId: "6E6CCAB337A34747A9AE02411DF39034",
              resourceAuthLevel: "IDENTITY_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "73D1A20FEEE84679BDF09CDEF802A693",
          code: "saleOrder",
          name: "企业侧管理平台-销售订单",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "58D0F377D320470DAE1EEC28405A732C",
              code: "saleOrder",
              name: "卖方销售订单",
              urlPath: "/oa-server/SaleDocument/saleDocumentList",
              parentId: "73D1A20FEEE84679BDF09CDEF802A693",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "9C9D41B6B52E4BA1ACD948D85088CA62",
              code: "saleOrder",
              name: "购物车列表",
              urlPath: "/business-server/Order/OrderCartList",
              parentId: "73D1A20FEEE84679BDF09CDEF802A693",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "AAC3F266C769449A86B282F68C5AEC11",
              code: "saleOrder",
              name: "删除购物车商品",
              urlPath: "/business-server/Order/DeleteOrderCart",
              parentId: "73D1A20FEEE84679BDF09CDEF802A693",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "CD192352F2E04624AE1132C41053A630",
              code: "saleOrder",
              name: "订单详情",
              urlPath: "/business-server/Order/DealDetails",
              parentId: "73D1A20FEEE84679BDF09CDEF802A693",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "ED01ED924D36429F9F686242B13CD4E0",
              code: "saleOrder",
              name: "已成交订单列表",
              urlPath: "/business-server/Order/ClosedOrderList",
              parentId: "73D1A20FEEE84679BDF09CDEF802A693",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "F94D1EA49E7545C2ABE3A23BDF242566",
              code: "saleOrder",
              name: "卖方销售订单转为交易订单",
              urlPath: "/oa-server/SaleDocument/transformToData",
              parentId: "73D1A20FEEE84679BDF09CDEF802A693",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "785D60932D2D4E1EA986E2627857787A",
          code: "10002",
          name: "仓库列表",
          urlPath: "/Warehouse/WarehouseList",
          parentId: "A04F7F7EBB24411C9AFEC2A3186EC17D",
          resourceAuthLevel: "AUTHORIZATION_REQUIRED",
          subResourceList: null,
        },
        {
          id: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
          code: "produce",
          name: "生产",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "AUTHORIZATION_REQUIRED",
          subResourceList: [
            {
              id: "00F299FC5FCB4BDD86DFC82C98CA6921",
              code: "produce",
              name: "已接收的码",
              urlPath: "/business-server/LinkCode/backCodeList",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "08BA42C7090841D58D56B3C57F48E5DE",
              code: "produce",
              name: "商品详情",
              urlPath:
                "/business-server/CommodityMgt/ProductionCommodityDetails",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "19D1912FFA40499EB5894BBBD1B4AAF8",
              code: "produce",
              name: "品牌列表",
              urlPath:
                "/business-server/Enterprise/findCurrEnterpriseBrandInfoList",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "2B77095329C44C25AED569FFF67BBE57",
              code: "produce",
              name: "添加品牌",
              urlPath: "/business-server/Enterprise/addBrandInfo",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "402D0025F67848E088EE4C3EC5B16214",
              code: "produce",
              name: "待接收的码",
              urlPath: "/business-server/LinkCode/queryCodeAllByEnterpriseId",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "4C6EADDC1D724B83ACEEBCE2F34B8CBC",
              code: "produce",
              name: "分类列表",
              urlPath: "/business-server/ProductionClassify/Catalog",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "594D24177E17400E8A587586DA226C7C",
              code: "produce",
              name: "新增商品",
              urlPath:
                "/business-server/CommodityMgt/InsertProductionCommodity",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "663456CDB0994B2F992C1D6CE7740BE5",
              code: "produce",
              name: "请求打码",
              urlPath: "/business-server/LinkCode/insertCode",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "861A407AE5DC4016BC0C4C5E4FBA22C6",
              code: "produce",
              name: "品牌详情",
              urlPath: "/business-server/Enterprise/findBrandInfoById",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "90096FA4B98D435DA83F042F679696D3",
              code: "produce",
              name: "删除商品",
              urlPath:
                "/business-server/CommodityMgt/DeleteProductionCommodity",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "BD406081A7834327BC8043CACB64F046",
              code: "produce",
              name: "三级目录",
              urlPath:
                "/business-server/ProductionClassify/queryCategoriesCatalog",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "C155EA687BC144A3A09359A4A125A134",
              code: "produce",
              name: "商品列表",
              urlPath: "/business-server/CommodityMgt/ProductionCommodityList",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "C483D5C0EE8B4DCEBFACEBA260AEC2D5",
              code: "produce",
              name: "修改分类",
              urlPath: "/business-server/ProductionClassify​/UpdateClassify",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "C6AB41F065AE48929A0C50D5CCAFE70E",
              code: "produce",
              name: "生成码列表",
              urlPath: "/business-server/LinkCode/codeInfoPlanList",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "D2500AF30EDB46359F43BCA3A0D3C00C",
              code: "produce",
              name: "品牌修改",
              urlPath: "/business-server/Enterprise/updateBrandInfo",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "D6D9478152814BEE9C95461B1F2C005A",
              code: "produce",
              name: "修改商品",
              urlPath:
                "/business-server/CommodityMgt/UpdateProductionCommodity",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "DEA6784DC95349E9AF0D2FFF7A869D3B",
              code: "produce",
              name: "生码下的所有编码",
              urlPath: "/business-server/LinkCode/codeAll",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "E0DD1C63C0054B8C9A87D5023CAF4216",
              code: "produce",
              name: "新增分类",
              urlPath: "/business-server/ProductionClassify/InsertClassify",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "F0F4DB0981F7420DB6AEE27CDB824CF3",
              code: "produce",
              name: "删除分类",
              urlPath: "/business-server/ProductionClassify/DeleteClassify",
              parentId: "7FFF3BDA1CF84C6CBBC5267E0A8A2992",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "8CFB41BBCCED4C06AD628D5054E66687",
          code: "SH-OA",
          name: "SH-OA",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "CB3728D1603146A7B6393EFB8B3BB678",
              code: "documentList",
              name: "已办件",
              urlPath: "/platform-audit-server/Document/documentList",
              parentId: "8CFB41BBCCED4C06AD628D5054E66687",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "F1A01C4C58234F80A164F0C488D086D5",
              code: "documentList",
              name: "未办件",
              urlPath: "/platform-audit-server/Document/documentList",
              parentId: "8CFB41BBCCED4C06AD628D5054E66687",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "9FB019B8050E417D8EB9B6CE93FC4A8D",
          code: "stock",
          name: "企业侧管理平台-库管",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "14805D3C89F343658C3640E7386EC899",
              code: "stock",
              name: "出库单详情",
              urlPath: "/storehouse-server/ExWarehouse/ExWarehouseDetails",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "2AEE0859BCB24C00BA45939DA0741228",
              code: "stock",
              name: "新增仓库",
              urlPath: "/storehouse-server/Warehouse/InsertWarehouse",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "4E406C68EE234F4FB582C8A08A4D2E2B",
              code: "stock",
              name: "仓库详情",
              urlPath: "/storehouse-server/Warehouse/WarehouseDetail",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "6F25216510D34D9AB193FFA85B9FE8EA",
              code: "stock",
              name: "删除仓库",
              urlPath: "/storehouse-server/Warehouse/DeleteWarehouse",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "B1F136158A964564A95713531AB194B0",
              code: "stock",
              name: "出库单列表",
              urlPath: "/storehouse-server/ExWarehouse/ExWarehouseList",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "DFEB04B041AD461F94F8F2E247DCD9D3",
              code: "stock",
              name: "新建出库单",
              urlPath: "/storehouse-server/ExWarehouse/InsertExWarehouse",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "E45B57DFC895437A9DC39DF9B6CC789E",
              code: "stock",
              name: "仓库列表",
              urlPath: "/storehouse-server/Warehouse/WarehouseList",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "ECF04ED07B0C46EB94AE52B5596B9BF4",
              code: "stock",
              name: "编辑仓库",
              urlPath: "/storehouse-server/Warehouse/UpdateWarehouse",
              parentId: "9FB019B8050E417D8EB9B6CE93FC4A8D",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "A5AE26D1792C4CD1B58AC48EFDDE2C5B",
          code: "external_LinkCode_backCode",
          name: "收码",
          urlPath: "/external-server/LinkCode/backCode",
          parentId: "1E34E268E6254E5ABF24B0BDDBF002C7",
          resourceAuthLevel: "PUBLIC",
          subResourceList: null,
        },
        {
          id: "C58EDDDD79C8433CAC27349538A361B5",
          code: "10004",
          name: "入库单列表",
          urlPath: "/Order/queryScanInOrderList",
          parentId: "0E7EC4EFB9374E5AA28F0C3963CE001D",
          resourceAuthLevel: "AUTHORIZATION_REQUIRED",
          subResourceList: null,
        },
        {
          id: "DD0450FACFC54CDFBC44B99A4369BDCB",
          code: "check",
          name: "企业侧管理平台-检测认证",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "28D8C994A9774EC9A8E1D4DF1ED6E655",
              code: "check",
              name: "查询企业检测列表",
              urlPath: "/business-server/Detect/queryDetectList",
              parentId: "DD0450FACFC54CDFBC44B99A4369BDCB",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "E4C3BF00D16B46DF888D941FCED01797",
              code: "check",
              name: "检测id查看详情",
              urlPath: "/business-server/Detect/queryDetecDetails",
              parentId: "DD0450FACFC54CDFBC44B99A4369BDCB",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
          ],
        },
        {
          id: "E13A28D370364A15A0DC1F363CD64D2E",
          code: "org",
          name: "企业管理平台-人事",
          urlPath: "",
          parentId: "",
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "183F0C29F5BC4763BADED5EE9A859D11",
              code: "org",
              name: "组织配置",
              urlPath: "",
              parentId: "E13A28D370364A15A0DC1F363CD64D2E",
              resourceAuthLevel: "PUBLIC",
              subResourceList: [
                {
                  id: "0052FE82BACF438398B7D44CE64CA754",
                  code: "org",
                  name: "分配岗位",
                  urlPath: "/org-server/Identity/userAddRole",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "20226FFB03BA467B85447BC807EB33A7",
                  code: "org",
                  name: "根据 ID 查找岗位",
                  urlPath: "/org-server/Role/findRoleById",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "3BFF81582009455198C110788114887C",
                  code: "org",
                  name: "批量删除岗位",
                  urlPath: "/org-server/Role/batchDelete",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "436D8C93D12F417590469C7BE1FBA0BD",
                  code: "org",
                  name: "组织列表",
                  urlPath: "/org-server/Org/queryDtoList",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "459DD2618AA743C08859C6B81F39D8DA",
                  code: "org",
                  name: "批量删除组织",
                  urlPath: "/org-server/Org/batchDelete",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "4F3D05D5B6CF46EFBAEBFCA1D82C2398",
                  code: "org",
                  name: "根据 ID 查找组织",
                  urlPath: "/org-server/Org/findById",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "5EDAEBC032E64A1C8E34E41EEF69FEA5",
                  code: "org",
                  name: "删除部门",
                  urlPath: "/org-server/Dept/delete",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "6719C95D841C47CFAA943D960C3C19B7",
                  code: "org",
                  name: "修改组织",
                  urlPath: "/org-server/Org/update",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "8F9764F2DD104D97999661137290F93F",
                  code: "org",
                  name: "删除岗位",
                  urlPath: "/org-server/Role/delete",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "92BD59D111DA4765AD976C8FCECA770D",
                  code: "org",
                  name: "新增部门",
                  urlPath: "/org-server/Dept/create",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "93C06A090D35432CB59FA37F8B2C5E3D",
                  code: "org",
                  name: "获取所有组织树",
                  urlPath: "/org-server/Org/findAllOrgTree",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "98E45C8E07C049E0AE0FC1E12A0D95BA",
                  code: "org",
                  name: "新增组织",
                  urlPath: "/org-server/Org/create",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "B66D439A75D843E68F891152591EE03B",
                  code: "org",
                  name: "修改部门",
                  urlPath: "/org-server/Dept/update",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "C620285F709E4CF9B36C36626D4B0CDD",
                  code: "org",
                  name: "修改岗位",
                  urlPath: "/org-server/Role/updateRole",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "C80C033B93064BDCB62FF20145A2FF4C",
                  code: "org",
                  name: "岗位列表",
                  urlPath: "/org-server/Role/findRoleList",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "D72D714E620A475BB75CB510F8F73F4E",
                  code: "org",
                  name: "批量删除部门",
                  urlPath: "/org-server/Dept/batchDelete",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "D92BF0EA3BD04041A2B4F235BA8D1284",
                  code: "org",
                  name: "获取指定范围数据",
                  urlPath: "/org-server/Group/loadAllGroupTree",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "E6DF7F8AC78C43519C49C72BBB55E3D0",
                  code: "org",
                  name: "部门列表",
                  urlPath: "/org-server/Dept/queryList",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "E9BCAAB6ADE3420782F25291C8497BC7",
                  code: "org",
                  name: "根据条件查找组织",
                  urlPath: "/org-server/Org/queryDtoList",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "F5229E6A020D42B38B910DA1BBD57958",
                  code: "org",
                  name: "根据 ID 查找部门",
                  urlPath: "/org-server/Dept/findById",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "F7F5873B47914B5EB9D744ED3F40EAB0",
                  code: "org",
                  name: "删除组织",
                  urlPath: "/org-server/Org/delete",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "FACF57F1A78F4DD899CB2910AD6CA0DD",
                  code: "org",
                  name: "新增岗位",
                  urlPath: "/org-server/Role/createRole",
                  parentId: "183F0C29F5BC4763BADED5EE9A859D11",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
              ],
            },
            {
              id: "AD909084745146DE9E22C366963B1441",
              code: "org",
              name: "职员配置",
              urlPath: "",
              parentId: "E13A28D370364A15A0DC1F363CD64D2E",
              resourceAuthLevel: "PUBLIC",
              subResourceList: [
                {
                  id: "2AB40F227ABF428C8DC9079911300098",
                  code: "org",
                  name: "职员列表",
                  urlPath: "/org-server/User/queryList",
                  parentId: "AD909084745146DE9E22C366963B1441",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "499CA8EB36CB4087B65C876584DE01F2",
                  code: "org",
                  name: "修改职员",
                  urlPath: "/org-server/User/updateUser",
                  parentId: "AD909084745146DE9E22C366963B1441",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "6F92F52EC73642CD8DEA1F54BE8D589D",
                  code: "org",
                  name: "根据 id 获取用户信息",
                  urlPath: "/org-server/User/findUserById",
                  parentId: "AD909084745146DE9E22C366963B1441",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "EA04F400446F48159137168D8D675259",
                  code: "org",
                  name: "新增职员",
                  urlPath: "/org-server/User/createUser",
                  parentId: "AD909084745146DE9E22C366963B1441",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
              ],
            },
          ],
        },
        {
          id: "E453868B727D4E06A946C9DC283F6682",
          code: "10001",
          name: "PDA库存管理中心",
          urlPath: "/storehouse-server",
          parentId: "",
          resourceAuthLevel: "AUTHORIZATION_REQUIRED",
          subResourceList: [
            {
              id: "A2D29584192C4BFB9E666383DE1BB823",
              code: "10002",
              name: "仓库列表",
              urlPath: "/Warehouse/WarehouseList",
              parentId: "E453868B727D4E06A946C9DC283F6682",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "BB4C79BBB4584BF7A7E90D8BD3A03D1E",
              code: "10003",
              name: "入库扫描",
              urlPath: "/Scan/scanIn",
              parentId: "E453868B727D4E06A946C9DC283F6682",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "CF1E24B025304D2DAA18B4D170E5D24F",
              code: "10004",
              name: "入库单列表",
              urlPath: "/Order/queryScanInOrderList",
              parentId: "E453868B727D4E06A946C9DC283F6682",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "EE5D3011DBA24EE5B332184689AAEF50",
              code: "ScanIn",
              name: "ScanIn",
              urlPath: "/storehouse-server/Scan/scanIn",
              parentId: "E453868B727D4E06A946C9DC283F6682",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
          ],
        },
        {
          id: "GetCurrUser",
          code: "System_USER_GetCurrUser",
          name: "获取当前用户",
          urlPath: "/org-server/User/getCurrUser",
          parentId: "User",
          resourceAuthLevel: "LOGIN_REQUIRED",
          subResourceList: null,
        },
        {
          id: "System",
          code: "System",
          name: "系统",
          urlPath: null,
          parentId: null,
          resourceAuthLevel: "PUBLIC",
          subResourceList: [
            {
              id: "LOGIN",
              code: "LOGIN",
              name: "登录",
              urlPath: "/login",
              parentId: "System",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
            {
              id: "Resource",
              code: "System_Resource",
              name: "资源",
              urlPath: null,
              parentId: "System",
              resourceAuthLevel: "PUBLIC",
              subResourceList: [
                {
                  id: "Create",
                  code: "System_Resource_Create",
                  name: "创建",
                  urlPath: "/org-server/Resource/create",
                  parentId: "Resource",
                  resourceAuthLevel: "PUBLIC",
                  subResourceList: null,
                },
                {
                  id: "Delete",
                  code: "System_Resource_Delete",
                  name: "删除",
                  urlPath: "/org-server/Resource/delete",
                  parentId: "Resource",
                  resourceAuthLevel: "PUBLIC",
                  subResourceList: null,
                },
                {
                  id: "EE757DA2F9DA4146A0C8009A0099FDF8",
                  code: "query",
                  name: "查找",
                  urlPath: "query",
                  parentId: "Resource",
                  resourceAuthLevel: "AUTHORIZATION_REQUIRED",
                  subResourceList: null,
                },
                {
                  id: "LoadAllGroupTree",
                  code: "System_Resource_LoadAllGroupTree",
                  name: "获取所有权限树",
                  urlPath: "/org-server/Resource/loadAllResourceTree",
                  parentId: "Resource",
                  resourceAuthLevel: "PUBLIC",
                  subResourceList: null,
                },
                {
                  id: "Update",
                  code: "System_Resource_Update",
                  name: "修改",
                  urlPath: "/org-server/Resource/update",
                  parentId: "Resource",
                  resourceAuthLevel: "PUBLIC",
                  subResourceList: null,
                },
              ],
            },
            {
              id: "Test",
              code: "Test",
              name: "测试",
              urlPath: "/org-server/Test/test",
              parentId: "System",
              resourceAuthLevel: "AUTHORIZATION_REQUIRED",
              subResourceList: null,
            },
            {
              id: "USER",
              code: "System_USER",
              name: "用户",
              urlPath: null,
              parentId: "System",
              resourceAuthLevel: "PUBLIC",
              subResourceList: null,
            },
          ],
        },
      ],
      resourceCodeSet: [
        "purchase",
        "logistics",
        "check",
        "stock",
        "produce",
        "internalManage",
        "promotion",
      ],
      val: "",
      inp: ["a", "b"],

      t1: [
        {
          barcode: "6933417481553",
          commodityName: "广东韶关正宗马坝油粘米",
          specsParameter: "10KG",
          brandName: "粤马",
          tradePrice: 86,
          sellingPrice: 188,
          quantity: 1,
          index: 1,
          num: 0,
          bili: "暂无",
          xiang: 0,
        },
        {
          barcode: "6927096511552",
          commodityName: "2021正宗马坝油粘米",
          specsParameter: "5kg",
          brandName: "粤马",
          tradePrice: 65.5,
          sellingPrice: 128,
          quantity: 3,
          index: 2,
          num: 0,
          bili: "暂无",
          xiang: 0,
        },
        {
          barcode: "6933417481213",
          commodityName: "20正宗马坝油粘米",
          specsParameter: "5kg",
          brandName: "粤马",
          tradePrice: 55,
          sellingPrice: 88,
          quantity: 1,
          index: 3,
          num: 0,
          bili: "暂无",
          xiang: 0,
        },
      ],
      t2: [
        {
          commodityCode: "1111",
          commodityName: "广东韶关正宗马坝油粘米",
          barcode: "6933417481553",
          dealNumber: "DD202201250001",
          brandName: "粤马",
          specsParameter: "10KG",
          index: 1,
          parentNumber: "-",
        },
        {
          commodityCode: "3333",
          commodityName: "2021正宗马坝油粘米",
          barcode: "6927096511552",
          dealNumber: "DD202201250001",
          brandName: "粤马",
          specsParameter: "5kg",
          index: 1,
          parentNumber: "-",
        },
        {
          commodityCode: "34234",
          commodityName: "20正宗马坝油粘米",
          barcode: "6933417481213",
          dealNumber: "DD202201250001",
          brandName: "粤马",
          specsParameter: "5kg",
          index: 1,
          parentNumber: "-",
        },
      ],
    };
  },

  created() {
    // this.getdetectionPdf();
    // console.log(this.t);

    // console.log(getId(this.t, "purchase"));
    let Arr = [];
    function e(arr, codeName) {
      for (const list of arr) {
        if (list.code === codeName) {
          Arr.push(list.id);
        }
        if (list.subResourceList != null) {
          e(list.subResourceList, codeName);
        }
      }
    }

    // e(this.t, "promotion");
    for (let i = 0; i < this.resourceCodeSet.length; i++) {
      e(this.t, this.resourceCodeSet[i]);
      // console.log(i);
    }
    // console.log(Arr);
    // console.log(Arr);

    // let arry = [];

    // this.resourceCodeSet.forEach((a, b, c) => {
    //   arry.push({
    //     name: a,
    //     id: Arr,
    //   });

    // });
    // console.log(arry);
    //[{ name:'purchase' ,id:['123','234'] }  ]
    //

    // function a(item, code) {
    //   return item.filter((item, index, arry) => {
    //     if (arry[index].subResourceList != null) {
    //     }
    //     return item.code === code;
    //   });
    // }

    // let t = a(this.t, "logistics");
    // console.log(t);

    // function recursive(arr, codeName) {
    //   var result = {};
    //   arr.forEach((item) => {
    //     if (item.code === codeName) {
    //       result = item;
    //     }

    //     if (item.subResourceList) {
    //       recursive(item.subResourceList, codeName);
    //     }
    //   });
    //   return result;
    // }

    // let d = recursive(this.t, "promotion");

    // console.log(d);

    // function getId(arr) {
    //   var arry = [];
    //   arr.forEach((item) => {
    //     arry.push(item.id);
    //   });
    //   if (arr.subResourceList) {
    //     getId(arr.subResourceList);
    //   }
    //   return arry;
    // }

    // console.log(getId(d.subResourceList));

    // let arr = [];
    // function b(arr, codeName) {
    //   for (let i = 0; i < arr.length; i++) {
    //     if (arr[i].subResourceList != null) {
    //       console.log(arr[i].subResourceList);
    //     }
    //     if (arr[i].code === codeName) {
    //       // return arr[i].id;
    //     }
    //   }
    // }
    // let c = b(this.t, "promotion");
    // // c.push();
    // console.log(c);
    // console.log(arr);
    // this.test();
    // console.log(this.t1);
    // console.log(this.t2);

    this.t1 = this.t1.reduce((obj, item) => {
      let idx = 0;
      for (let i = 0; i < this.t2.length; i++) {
        if (item.barcode === this.t2[i].barcode) {
          idx++;
        }
      }
      let arr = {
        ...item,
        frequency: idx,
      };
      obj.push(arr);
      return obj;
    }, []);
    // console.log(this.t1);

    let list2 = [
      {
        id: 11,
        name: "gg",
        num: 5,
      },
      {
        id: 55,
        name: "aa",
        num: 7,
      },
      {
        id: 88,
        name: "pig",
        num: 2,
      },
      {
        id: 44,
        name: "apple",
        num: 2,
      },
    ];
    let list = [
      {
        id: 11,
        name: "apple",
        num: 2,
      },
      {
        id: 33,
        name: "apple",
        num: 3,
      },
      {
        id: 22,
        name: "pig",
        num: 2,
      },
      {
        id: 44,
        name: "apple",
        num: 2,
      },
    ];

    // list = list.reduce((obj, item) => {
    //   let arr
    //   for (let i = 0; i < list2.length; i++) {
    //     if (item.id === list2[i].id) {
    //       arr = list2[i]
    //     }
    //   }
    //   arr? obj.push(arr):obj.push(item)
    //   return obj;
    // }, []);

    // list2 = list2.reduce((obj, item) => {
    //   let idx = 0;
    //   for (let i = 0; i < list.length; i++) {
    //     if (item.id === list[i].id) {
    //       idx++;
    //     }
    //   }
    //   idx === 0 && obj.push(item);
    //   return obj;
    // }, []);
    // console.log(list2);

    // list = list.reduce((obj, item) => {

    //   for (let i = 0; i < list2.length; i++) {

    //     if (item.id != list2[i].id) {
    //       // console.log(list2[i]);
    //       // obj.push(list2[i]);
    //     }
    //   }

    //   return obj;
    // }, []);
    // console.log(list);
  },

  methods: {
    async getdetectionPdf() {
      const data = await this.$API.pdf.getdetectionPdf({
        object: {
          barcode: "6951184200196",
          enterpriseId: "613748F65B9B4824A8DBB05045F0E19A",
        },
      });
      console.log(data.data.data);
      let path = data.data.data.url.split("//")[1];
      this.src = `http://14.29.162.130:6603/file/fileDownload/${path} `;
      console.log(this.src);
    },
    changePdfPage(val) {
      // console.log(val)
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
        // console.log(this.currentPage)
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
        // console.log(this.currentPage)
      }
    },
    a() {},
    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    },

    test() {
      // console.log(this.val);
      try {
        let b = true;

        this.inp.forEach((item) => {
          if (this.val === item) {
            b = false;
            throw "Jump out now!";
          }
          if (b) {
            this.inp.push(this.val);
            this.val = "";
          }
        });
      } catch (e) {
        console.log(e);
      }

      // console.log(arr);
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  display: flex;
  justify-content: center;
  .t {
    width: 1000px;
    // height: 1200px;
    background: #fff;
  }
}
</style>